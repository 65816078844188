<script setup lang="ts">
const props = defineProps<{
	name: string
	alt?: string
}>();
const { $config } = useNuxtApp();

const getUrl = () => {
	return `https://firebasestorage.googleapis.com/v0/b/${$config.public.FIREBASE_MAIN_STORAGE_BUCKET}/o\/${props.name}?alt=media`;
};
</script>

<template>
	<img :src="getUrl()" :alt="alt" />
</template>
